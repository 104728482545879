import { Fornecedor, Produto } from '../../protocols';
import { defineRegraImpostoCredito } from '../Auxiliares/DefineRegraImpostoCredito';

type FieldsParams = {
  valTotTabFiscal: number;
  valTotTabFiscalGer: number;
};

type PisCofins = {
  valCredPISCofinsGerenc: number;
};

export const calculaPisCofins = (
  produto: Produto,
  fornecedor: Fornecedor,
  fields: FieldsParams,
  FLGSTPISCOF = false,
  FLGIPIPISCOF = false,
): PisCofins => {
  const { valTotTabFiscal, valTotTabFiscalGer } = fields;

  const isNaoCredPisCof = defineRegraImpostoCredito(
    // false, // TAB_ID_CTB.FLG_IGNORA_PIS_COFINS
    produto.flg_ignora_pis_cofins,
    produto.flg_nao_pis_cofins,
    fornecedor.flg_nao_transf_icms,
    fornecedor.flg_nao_transf_pis_cofins,
  );

  let valTotCustoPisCofins = 0;
  let valTotCustoPisCofinsGer = 0;

  const valTotST = produto.val_st_calc || 0;
  const valTotSTFCP = produto.val_tot_st_fcp || 0;

  if (FLGSTPISCOF && FLGIPIPISCOF) {
    valTotCustoPisCofins =
      valTotTabFiscal + produto.val_tot_ipi + valTotST + valTotSTFCP;

    valTotCustoPisCofinsGer =
      valTotTabFiscalGer +
      produto.val_tot_ipi +
      valTotST +
      valTotSTFCP +
      produto.val_tot_fidelidade;
  } else if (!FLGSTPISCOF && FLGIPIPISCOF) {
    valTotCustoPisCofins = valTotTabFiscal + produto.val_tot_ipi;

    valTotCustoPisCofinsGer =
      valTotTabFiscalGer + produto.val_tot_ipi + produto.val_tot_fidelidade;
  } else if (FLGSTPISCOF && !FLGIPIPISCOF) {
    valTotCustoPisCofins = valTotTabFiscal + valTotST + valTotSTFCP;

    valTotCustoPisCofinsGer =
      valTotTabFiscalGer + valTotST + valTotSTFCP + produto.val_tot_fidelidade;
  } else if (!FLGSTPISCOF && !FLGIPIPISCOF) {
    valTotCustoPisCofins = valTotTabFiscal;
    valTotCustoPisCofinsGer = valTotTabFiscalGer + produto.val_tot_fidelidade;
  }

  const FLGPISCOFINSXML = false; // Vem da importação do XML
  let valCredPISCofins = 0;
  let valCredPISCofinsGerenc = 0;
  let valTotalPisCofins = 0;

  if (FLGPISCOFINSXML) {
    if (isNaoCredPisCof) {
      valCredPISCofins = 0;
      valCredPISCofinsGerenc = 0;
    } else {
      valTotalPisCofins = produto.val_pis_xml + produto.val_cofins_xml;
      valCredPISCofins = valTotalPisCofins / produto.qtd_total;
      valCredPISCofinsGerenc = valCredPISCofins;
      produto.val_imp_pis = produto.val_pis_xml / produto.qtd_total;
      produto.val_imp_cofins = produto.val_cofins_xml / produto.qtd_total;
    }
  } else if (isNaoCredPisCof) {
    valCredPISCofins = 0;
    valCredPISCofinsGerenc = 0;
  } else {
    valCredPISCofins =
      (valTotCustoPisCofins *
        ((produto.per_aliq_pis + produto.per_aliq_cofins) / 100)) /
      produto.qtd_total;

    valCredPISCofinsGerenc =
      (valTotCustoPisCofinsGer *
        ((produto.per_aliq_pis + produto.per_aliq_cofins) / 100)) /
      produto.qtd_total;

    produto.val_imp_pis =
      (valTotCustoPisCofinsGer * (produto.per_aliq_pis / 100)) /
      produto.qtd_total;

    produto.val_imp_cofins =
      (valTotCustoPisCofinsGer * (produto.per_aliq_cofins / 100)) /
      produto.qtd_total;
  }

  return {
    valCredPISCofinsGerenc,
  };
};
