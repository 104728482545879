export function formatPerfil(Perfil: any) {
  return {
    value: Perfil.cod_perfil,
    label: Perfil.descricao,
    cod_categoria: Perfil.cod_categoria,
    cod_cc: Perfil.cod_cc,
    cod_condicao: Perfil.cod_condicao,
    cod_finalizadora: Perfil.cod_finalizadora,
    cod_perfil: Perfil.cod_perfil,
    des_perfil: Perfil.des_perfil,
    descricao: Perfil.descricao,
    num_condicao: Perfil.num_condicao,
    per_desconto: Perfil.per_desconto,
    tipo_emissao: Perfil.tipo_emissao,
    tipo_nf: Perfil.tipo_nf,
    tipo_operacao: Perfil.tipo_operacao,
    tipo_unidade: Perfil.tipo_unidade,
    flg_gera_financeiro: Perfil.flg_gera_financeiro,
  };
}
