import { Produto } from '../../protocols';

export const calculaFcp = (produto: Produto, ICMSXML = false): void => {
  const TIPOREGIMEFCP = 0 + 0; // TODO - verificar

  if (ICMSXML) {
    if ([0, 2].includes(produto.tipo_trib_entrada)) {
      produto.val_tot_icms_fcp = produto.val_tot_icms_fcp_xml;
    } else {
      produto.val_tot_icms_fcp = 0;
    }
  } else if (
    produto.val_tot_bc_icms > 0 &&
    produto.per_fcp_icms > 0 &&
    !produto.flg_nao_calc_bcicms
  ) {
    produto.val_tot_icms_fcp = parseFloat(
      (produto.val_tot_bc_icms * (produto.per_fcp_icms / 100)).toFixed(2),
    );
  } else if (!produto.flg_nao_calc_bcicms) {
    produto.val_tot_icms_fcp = 0;
  }

  if (TIPOREGIMEFCP === 0) {
    produto.val_tot_icms_fcp = 0;
  }
};
