import { Fornecedor, Perfil, Produto } from '../../protocols';
import { calculaValoresPisCofins } from './CalculaValoresPisCofins';
import { converteCSTPisCofinsXML } from './ConverteCSTPisCofinsXML';

// interface Produto {
//   val_base_pis: number;
//   val_base_cofins: number;
//   val_pis: number;
//   val_cofins: number;
//   val_isento_pis_cofins: number;
//   val_aliq_zero: number;
//   val_monofasico: number;
//   val_substituicao: number;
//   val_nao_tribut: number;
//   val_imune: number;
//   val_suspensao: number;
// }

interface FieldsParams {
  flgIgnoraIdCtb: boolean;
  flgNaoPisCofins: boolean;
  flgNaoTransfCredForn: boolean;
  flgStPisCofins: boolean;
  flgIpiPisCofins: boolean;
  tipoNaoPisCofins: number;
  valTotal: number;
  valIcmsSt: number;
  valIpi: number;
  perPis: number;
  perCofins: number;
  valIcms: number;
}

interface MTabs {
  flgIgnoraPisCofins: boolean;
  flgNaoPisCofins: boolean;
  tipoNaoPisCofins: number;
  valTotNf: number;
  valTotSt: number;
  valTotStFcp: number;
  valTotIpi: number;
  perAliqPis: number;
  perAliqCofins: number;
  valIcms: number;
  valPisXml: number | null;
  valCofinsXml: number | null;
  valBcPisXml: number | null;
  valBcCofinsXml: number | null;
  cstPisXml: string | null;
  cstCofinsXml: string | null;
  cstPis: string;
  cstCofins: string;
  valPis: number;
  valCofins: number;
}

interface ParametroLoja {
  flg_st_bc_pis_cof: boolean;
  flg_ipi_bc_pis_cof: boolean;
  flg_exc_icms_bc_pis_ent: boolean;
}

// interface Perfil {
//   tipo_nf: number;
// }

// const calculaValoresPisCofins = (
//   flgIgnoraIdCtb: boolean,
//   flgNaoPisCofins: boolean,
//   flgNaoTransfCredForn: boolean,
//   flgStPisCofins: boolean,
//   flgIpiPisCofins: boolean,
//   tipoNaoPisCofins: number,
//   valTotal: number,
//   valIcmsSt: number,
//   valIpi: number,
//   perPis: number,
//   perCofins: number,
//   valIcms: number,
//   produto: Produto
// ): void => {
//   // Implementar a lógica do cálculo dos valores de PIS e COFINS aqui
//   // Essa lógica já foi fornecida anteriormente
//   // Atualizar o objeto produto com os valores calculados
// };

// const converteCSTPisCofinsXML = (
//   cstPisXml: string,
//   flgIgnoraPisCofins: string,
//   cstPis: string,
//   flg_nao_transf_pis_cofins: boolean,
//   tipo_nf: number
// ): string => {
//   // Implementar a lógica de conversão do CST de PIS e COFINS XML aqui
//   return ''; // Substituir pelo valor real
// };

export const processarPisCofins = (
  mTab: MTabs,
  fornecedor: Fornecedor,
  parametroLoja: ParametroLoja,
  perfil: Perfil,
  produto: Produto,
): void => {
  const {
    flgIgnoraPisCofins,
    flgNaoPisCofins,
    tipoNaoPisCofins,
    valTotNf,
    valTotSt,
    valTotStFcp,
    valTotIpi,
    perAliqPis,
    perAliqCofins,
    valIcms,
    valPisXml,
    valCofinsXml,
    valBcPisXml,
    valBcCofinsXml,
    cstPisXml,
    cstCofinsXml,
    cstPis,
    cstCofins,
    // valPis,
    // valCofins,
  } = mTab;

  const { flg_nao_transf_pis_cofins } = fornecedor;

  const { flg_st_bc_pis_cof, flg_ipi_bc_pis_cof, flg_exc_icms_bc_pis_ent } =
    parametroLoja;

  const { tipo_nf } = perfil;

  const vValIcms = flg_exc_icms_bc_pis_ent ? valIcms : 0.0; // val_tot_icms_op @TODO

  // type FieldsParams = {
  //   flgIgnoraIdCtb: boolean;
  //   flgNaoPisCofins: boolean;
  //   flgNaoTransfCredForn: boolean;
  //   flgStPisCofins: boolean;
  //   flgIpiPisCofins: boolean;
  //   tipoNaoPisCofins: number;
  //   valTotal: number;
  //   valIcmsSt: number;
  //   valIpi: number;
  //   perPis: number;
  //   perCofins: number;
  //   valIcms: number;
  // };

  const fields = {
    flgIgnoraIdCtb: flgIgnoraPisCofins,
    flgNaoPisCofins,
    flgNaoTransfCredForn: flg_nao_transf_pis_cofins,
    flgStPisCofins: flg_st_bc_pis_cof,
    flgIpiPisCofins: flg_ipi_bc_pis_cof,
    tipoNaoPisCofins,
    valTotal: produto.val_tabela_liq,
    valIcmsSt: valTotSt + valTotStFcp,
    valIpi: valTotIpi,
    perPis: perAliqPis,
    perCofins: perAliqCofins,
    valIcms: vValIcms,
  };

  const {
    valBasePis,
    valBaseCofins,
    valPis,
    valCofins,
    valIsentoPisCofins,
    valAliqZero,
    valMonofasico,
    valSubstituicao,
    valNaoTribut,
    valImune,
    valSuspensao,
  } = calculaValoresPisCofins(produto, fields);
  let vCstPis: string;
  let vCstCofins: string;
  let vValBasePis: number | null;
  let vPerPis: number;
  let vValPis: number | null;
  let vValBaseCofins: number | null;
  let vPerCofins: number;
  let vValCofins: number | null;

  const flgPisCofinsXML = false; // Sempre false

  if (flgPisCofinsXML) {
    vCstPis = converteCSTPisCofinsXML(
      cstPisXml,
      flgIgnoraPisCofins,
      cstPis,
      flg_nao_transf_pis_cofins,
      tipo_nf,
    );
    vValBasePis = valBcPisXml;
    vPerPis = perAliqPis;
    vValPis = valPisXml;

    vCstCofins = converteCSTPisCofinsXML(
      cstCofinsXml,
      flgIgnoraPisCofins,
      cstCofins,
      flg_nao_transf_pis_cofins,
      tipo_nf,
    );
    vValBaseCofins = valBcCofinsXml;
    vPerCofins = perAliqCofins;
    vValCofins = valCofinsXml;
  } else {
    vValBasePis = valBasePis;
    vValBaseCofins = valBaseCofins;
    vValPis = valPis;
    vValCofins = valCofins;
    vPerPis = perAliqPis;
    vPerCofins = perAliqCofins;

    if (flg_nao_transf_pis_cofins) {
      vPerPis = 0.0;
      vPerCofins = 0.0;
      vCstPis = '70';
      vCstCofins = '70';
    } else if (flgIgnoraPisCofins) {
      vPerPis = 0.0;
      vPerCofins = 0.0;
      vCstPis = '';
      vCstCofins = '';
    } else if (tipo_nf === 11) {
      vCstPis = '98';
      vCstCofins = '98';
    } else {
      vCstPis = cstPis;
      vCstCofins = cstCofins;
    }
  }

  // const vValIsentoPisCof = produto.val_isento_pis_cofins;
  // const vValAliqZeroPisCof = produto.val_aliq_zero;
  // const vValMonofPisCof = produto.val_monofasico;
  // const vValSubstPisCof = produto.val_substituicao;
  // const vValNaoTributPisCof = produto.val_nao_tribut;
  // const vValImunePisCof = produto.val_imune;
  // const vValSuspPisCof = produto.val_suspensao;

  // Atribuir os valores calculados às colunas correspondentes
  produto.val_pis = Number(vValPis || 0);
  produto.val_cofins = Number(vValCofins || 0);
  produto.val_base_pis = Number(vValBasePis || 0);
  produto.val_base_cofins = Number(vValBaseCofins || 0);
  produto.val_aliq_zero = valAliqZero;
  produto.cst_pis = vCstPis;
  produto.cst_cofins = vCstCofins;
};

// Exemplo de chamada da função processarPisCofins
// const mTabExample: MTabs = {
//   flgIgnoraPisCofins: false,
//   flgNaoPisCofins: false,
//   tipoNaoPisCofins: 1,
//   valTotNf: 1000.0,
//   valTotSt: 200.0,
//   valTotStFcp: 50.0,
//   valTotIpi: 100.0,
//   perAliqPis: 1.65,
//   perAliqCofins: 7.6,
//   valIcms: 150.0,
//   valPisXml: 16.5,
//   valCofinsXml: 76.0,
//   valBcPisXml: 1000.0,
//   valBcCofinsXml: 1000.0,
//   cstPisXml: '01',
//   cstCofinsXml: '01',
//   cstPis: '50',
//   cstCofins: '50',
//   valPis: 16.5,
//   valCofins: 76.0,
// };

// const fornecedorExample: Fornecedor = {
//   flg_nao_transf_pis_cofins: false,
// };

// const parametroLojaExample: ParametroLoja = {
//   flg_st_bc_pis_cof: true,
//   flg_ipi_bc_pis_cof: true,
//   flg_exc_icms_bc_pis_ent: false,
// };

// const perfilExample: Perfil = {
//   tipo_nf: 1,
// };

// processarPisCofins(
//   mTabExample,
//   fornecedorExample,
//   parametroLojaExample,
//   perfilExample,
// );
