import { Produto } from '../../protocols';

export const calculaBaseIpi = (produto: Produto): void => {
  const valTotIpi = produto.val_tot_ipi;
  const valTotSemIpiFreteSt =
    produto.val_tot_nf -
    (valTotIpi + produto.val_tot_st + produto.val_tot_frete);

  if (valTotIpi !== 0) {
    produto.val_base_ipi = valTotSemIpiFreteSt;
    produto.val_isento_ipi = 0;
    produto.val_outros_ipi = 0;
  } else {
    produto.val_base_ipi = 0;
    produto.val_isento_ipi = valTotSemIpiFreteSt;
    produto.val_outros_ipi = 0;
  }
};
