import { Produto } from '../../protocols';

export const calculaValoresIndividuais = (produto: Produto): void => {
  if (produto.qtd_total !== 0) {
    if (produto.val_tot_desp_acess !== 0)
      produto.val_desp_acess = produto.val_tot_desp_acess / produto.qtd_total;

    if (produto.val_tot_acrescimo !== 0)
      produto.val_acrescimo = produto.val_tot_acrescimo / produto.qtd_total;

    if (produto.val_tot_frete !== 0)
      produto.val_frete = produto.val_tot_frete / produto.qtd_total;

    // TODO - FRETE DEST

    // if (produto.val_tot_frete !== 0)
    // produto.val_frete = produto.val_tot_frete / produto.qtd_total;

    if (produto.val_tot_desconto !== 0)
      produto.val_desconto = produto.val_tot_desconto / produto.qtd_total;

    if (produto.val_tot_ipi !== 0)
      produto.val_ipi = produto.val_tot_ipi / produto.qtd_total;

    if (produto.val_tot_st !== 0)
      produto.val_st = produto.val_tot_st / produto.qtd_total;

    if (produto.val_tot_indeniza !== 0)
      produto.val_indeniza = produto.val_tot_indeniza / produto.qtd_total;
  }
};
