import { Produto } from '../../protocols';

export const calculaOutrosIcms = (produto: Produto): void => {
  if (!produto.flg_nao_calc_bcicms) {
    produto.val_tot_outros_icms =
      produto.val_tot_nf -
      (produto.val_tot_bc_icms +
        produto.val_tot_isento +
        produto.val_tot_st +
        produto.val_tot_st_fcp +
        produto.val_tot_ipi);

    if (produto.val_tot_outros_icms < 0) {
      produto.val_tot_outros_icms = 0;
    }
  }
};
