import React from 'react';
import { EntradaNFEContent } from './EntradaNFEContent';
import { EntradaNFEContextProvider } from './EntradaNFEContext';
import { TransmissaoProvider } from '~/components/Nfe/TransmissaoNFe/Transmissao';

export const EntradaNFE: React.FC = () => {
  return (
    <TransmissaoProvider>
      <EntradaNFEContextProvider>
        <EntradaNFEContent />
      </EntradaNFEContextProvider>
    </TransmissaoProvider>
  );
};
