import { Fornecedor, Loja, Produto } from '../../protocols';

export const inicializaPisCofins = (
  produto: Produto,
  fornecedor: Fornecedor,
  loja: Loja,
): void => {
  const {
    flg_nao_pis_cofins,
    flg_ignora_pis_cofins,
    tipo_pis_cofins_entrada,
    tipo_nao_pis_cofins_entrada,
  } = produto;

  if (flg_ignora_pis_cofins) {
    produto.flg_nao_pis_cofins = true;
    produto.tipo_nao_pis_cofins = -1;
  } else if (
    Number(produto.cod_cred_pres) > 0 &&
    Number(tipo_pis_cofins_entrada) !== -1
  ) {
    switch (tipo_pis_cofins_entrada) {
      case 0:
        produto.flg_nao_pis_cofins = true;
        produto.tipo_nao_pis_cofins = -1;
        break;
      case 1:
        produto.flg_nao_pis_cofins = true;
        produto.tipo_nao_pis_cofins = Number(tipo_nao_pis_cofins_entrada);
        break;
      default:
    }
  }

  if (fornecedor.flg_nao_transf_pis_cofins) {
    produto.flg_nao_pis_cofins = true;
    produto.tipo_nao_pis_cofins = -1;
  }

  if (!flg_nao_pis_cofins) {
    if (
      Number(produto.cod_cred_pres) > 0 &&
      !fornecedor.flg_nao_cred_presumido
    ) {
      // produto.per_aliq_pis = produto.per_aliq_pis;
      // produto.per_aliq_cofins = produto.per_aliq_cofins;
    } else {
      produto.per_aliq_pis = loja.val_pis;
      produto.per_aliq_cofins = loja.val_cofins;
    }
  } else {
    produto.per_aliq_pis = 0;
    produto.per_aliq_cofins = 0;
  }

  produto.cst_pis =
    produto.cst_pis_entrada !== null
      ? produto.cst_pis_entrada
      : produto.cst_pis_cof_entrada;
  produto.cst_cofins =
    produto.cst_cofins_entrada !== null
      ? produto.cst_cofins_entrada
      : produto.cst_pis_cof_entrada;
};
