import { Produto } from '../../protocols';

type FieldsParams = {
  flgIgnoraIdCtb: boolean;
  flgNaoPisCofins: boolean;
  flgNaoTransfCredForn: boolean;
  flgStPisCofins: boolean;
  flgIpiPisCofins: boolean;
  tipoNaoPisCofins: number;
  valTotal: number;
  valIcmsSt: number;
  valIpi: number;
  perPis: number;
  perCofins: number;
  valIcms: number;
};

export const calculaValoresPisCofins = (
  produto: Produto,
  fields: FieldsParams,
): {
  valBasePis: number;
  valBaseCofins: number;
  valPis: number;
  valCofins: number;
  valIsentoPisCofins: number;
  valAliqZero: number;
  valMonofasico: number;
  valSubstituicao: number;
  valNaoTribut: number;
  valImune: number;
  valSuspensao: number;
} => {
  const {
    flgIgnoraIdCtb,
    flgNaoPisCofins,
    flgNaoTransfCredForn,
    flgStPisCofins,
    flgIpiPisCofins,
    valTotal,
    valIcmsSt,
    valIpi,
    perPis,
    perCofins,
    valIcms,
    tipoNaoPisCofins,
  } = fields;

  let valBasePis = 0;
  let valBaseCofins = 0;
  let valPis = 0;
  let valCofins = 0;
  let valIsentoPisCofins = 0;
  let valAliqZero = 0;
  let valMonofasico = 0;
  let valSubstituicao = 0;
  let valNaoTribut = 0;
  let valImune = 0;
  let valSuspensao = 0;

  if (!flgIgnoraIdCtb && !flgNaoPisCofins && !flgNaoTransfCredForn) {
    if (flgStPisCofins && flgIpiPisCofins) {
      valBasePis = parseFloat((Number(valTotal) - Number(valIcms)).toFixed(2));
      valBaseCofins = parseFloat(
        (Number(valTotal) - Number(valIcms)).toFixed(2),
      );
      valPis = parseFloat(
        ((Number(valTotal) - Number(valIcms)) * (perPis / 100)).toFixed(2),
      );
      valCofins = parseFloat(
        (
          (Number(valTotal) - Number(valIcms)) *
          (Number(perCofins) / 100)
        ).toFixed(2),
      );
    } else if (!flgStPisCofins && !flgIpiPisCofins) {
      const base =
        Number(valTotal) - Number(valIcmsSt) - Number(valIpi) - Number(valIcms);
      valBasePis = parseFloat(base.toFixed(2));
      valBaseCofins = parseFloat(base.toFixed(2));
      valPis = parseFloat((base * (perPis / 100)).toFixed(2));
      valCofins = parseFloat((base * (Number(perCofins) / 100)).toFixed(2));
    } else if (!flgStPisCofins && flgIpiPisCofins) {
      const base = Number(valTotal) - Number(valIcmsSt) - Number(valIcms);
      valBasePis = parseFloat(base.toFixed(2));
      valBaseCofins = parseFloat(base.toFixed(2));
      valPis = parseFloat((base * (perPis / 100)).toFixed(2));
      valCofins = parseFloat((base * (Number(perCofins) / 100)).toFixed(2));
    } else {
      const base = Number(valTotal) - Number(valIpi) - Number(valIcms);
      valBasePis = parseFloat(base.toFixed(2));
      valBaseCofins = parseFloat(base.toFixed(2));
      valPis = parseFloat((base * (perPis / 100)).toFixed(2));
      valCofins = parseFloat((base * (Number(perCofins) / 100)).toFixed(2));
    }
  } else if (flgIgnoraIdCtb || flgNaoTransfCredForn) {
    if (flgStPisCofins && flgIpiPisCofins) {
      valNaoTribut = parseFloat(
        (Number(valTotal) - Number(valIcms)).toFixed(2),
      );
    } else if (!flgStPisCofins && !flgIpiPisCofins) {
      const base =
        Number(valTotal) - Number(valIcmsSt) - Number(valIpi) - Number(valIcms);
      valNaoTribut = parseFloat(base.toFixed(2));
    } else if (!flgStPisCofins && flgIpiPisCofins) {
      const base = Number(valTotal) - Number(valIcmsSt) - Number(valIcms);
      valNaoTribut = parseFloat(base.toFixed(2));
    } else {
      const base = Number(valTotal) - Number(valIpi) - Number(valIcms);
      valNaoTribut = parseFloat(base.toFixed(2));
    }
  } else {
    if (flgStPisCofins && flgIpiPisCofins) {
      valIsentoPisCofins = parseFloat(
        (Number(valTotal) - Number(valIcms)).toFixed(2),
      );
    } else if (!flgStPisCofins && !flgIpiPisCofins) {
      const base =
        Number(valTotal) - Number(valIcmsSt) - Number(valIpi) - Number(valIcms);
      valIsentoPisCofins = parseFloat(base.toFixed(2));
    } else if (!flgStPisCofins && flgIpiPisCofins) {
      const base = Number(valTotal) - Number(valIcmsSt) - Number(valIcms);
      valIsentoPisCofins = parseFloat(base.toFixed(2));
    } else {
      const base = Number(valTotal) - Number(valIpi) - Number(valIcms);
      valIsentoPisCofins = parseFloat(base.toFixed(2));
    }

    const base = Number(valTotal) - Number(valIcmsSt) - Number(valIcms);
    switch (tipoNaoPisCofins) {
      case 0:
        valAliqZero = parseFloat(base.toFixed(2));
        break;
      case 1:
        valMonofasico = parseFloat(base.toFixed(2));
        break;
      case 2:
        valSubstituicao = parseFloat(base.toFixed(2));
        break;
      case 3:
        valImune = parseFloat(base.toFixed(2));
        break;
      case 4:
        valSuspensao = parseFloat(base.toFixed(2));
        break;
      default:
        break;
    }
  }
  return {
    valBasePis: Number(valBasePis),
    valBaseCofins: Number(valBaseCofins),
    valPis: Number(valPis),
    valCofins: Number(valCofins),
    valIsentoPisCofins: Number(valIsentoPisCofins),
    valAliqZero: Number(valAliqZero),
    valMonofasico: Number(valMonofasico),
    valSubstituicao: Number(valSubstituicao),
    valNaoTribut: Number(valNaoTribut),
    valImune: Number(valImune),
    valSuspensao: Number(valSuspensao),
  };
};
