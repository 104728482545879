import { Produto } from '../../protocols';
import {
  calculaReducaoBaseCalculo,
  calculaValorST,
  calculaVendaVarejo,
} from '../Calculos';

type FieldsParams = {
  valTotTabFinalGer: number;
  valTotTabelaFiscal: number;
  isFornPauta: boolean;
  valPerRedBCEntrada: number;
  valTotVendaVarejo: number;
  valCustoTotSemIPISTFrete: number;
  valTotBCSTEntrada: number;
  valTotBcSt: number;
  valCustoTotSemST: number;
  isFornRegimeST: boolean;
  isFornNaoTransIcms: boolean;
  isFornSimples: boolean;
  isInterestadual: boolean;
  valTotalSTCred: number;
  valTotVendaVarejoGuia: number;
  valTotSTGuia: number;
  valCredIcmsCalc: number;
  valCredIcmsGerencCalc: number;
  valTotTabLiq: number;
  valCredPISCofinsGerenc: number;
};

export const calculaValoresUnitarios = (
  produto: Produto,
  fields: FieldsParams,
  IMPORTNFE = false,
  FLGSTXML = false,
) => {
  const {
    valTotTabFinalGer,
    valTotTabelaFiscal,
    isFornPauta,
    valTotVendaVarejo,
    valCustoTotSemIPISTFrete,
    valTotBCSTEntrada,
    valTotBcSt,
    valCustoTotSemST,
    isFornRegimeST,
    isFornNaoTransIcms,
    isFornSimples,
    isInterestadual,
    valTotVendaVarejoGuia,
    valTotSTGuia,
    valCredIcmsCalc,
    valCredIcmsGerencCalc,
    valTotTabLiq,
    valCredPISCofinsGerenc,
  } = fields;

  let { valPerRedBCEntrada, valTotalSTCred } = fields;
  /** TODO backend */
  // produto.val_custo_tabela = produto.val_tabela / produto.qtd_embalagem;
  /** */

  // let valCredIcms = 0;
  // let valCredIcmsGerenc = 0;

  produto.val_custo_rep = valTotTabFinalGer / produto.qtd_total;
  produto.val_custo_cheio = valTotTabelaFiscal / produto.qtd_total;

  /** TODO backend */
  // if (
  //   (produto.flg_veda_cred || produto.flg_veda_cred_lim) &&
  //   !isFornNaoTransIcms
  // ) {
  //   valCredIcms = (produto.val_tot_icms_op + produto.val_tot_icms_fcp) / produto.qtd_total;
  //   valCredIcmsGer =
  //     (produto.val_tot_icms_op_gerenc + produto.val_tot_icms_fcp) / produto.qtd_total;
  // }

  produto.val_custo_sicms = valTotTabLiq / produto.qtd_total - valCredIcmsCalc;

  // const valImpostoCredito = (valCredIcms + valCredPISCofins) * produto.qtd_total;
  // 0,285
  const valImpostoCreditoGerenc =
    // 0,07125                // 0                      // 4
    (valCredIcmsGerencCalc + valCredPISCofinsGerenc) * produto.qtd_total;

  // produto.val_custo_scred_brt_fisc = valTotTabelaFiscal / produto.qtd_total;
  produto.val_imp_icms =
    valCredIcmsGerencCalc - produto.val_tot_icms_fcp / produto.qtd_total;
  // produto.val_imp_icms_fiscal =
  //   valCredIcms - produto.val_tot_icms_fcp / produto.qtd_total;

  // produto.val_imposto_credito_un = valCredIcms + valCredPISCofins;
  // produto.val_imposto_credito = valImpostoCreditoGerenc;

  // produto.val_imposto_credito_fiscal = valImpostoCredito;

  produto.val_custo_scred =
    (valTotTabFinalGer - valImpostoCreditoGerenc) / produto.qtd_total;

  // produto.val_custo_scred_fisc =
  //   (valTotTabFinal - valImpostoCredito) / produto.qtd_total;
  /** */

  const val_tot_bc_st_frete_dest = calculaVendaVarejo(
    produto.per_pauta_iva,
    produto.per_pauta_iva_ncm,
    0,
    produto.val_tot_frete_dest,
    produto.qtd_total,
    isFornPauta,
  );
  produto.val_tot_bc_st_frete_dest = val_tot_bc_st_frete_dest;

  produto.val_tot_bc_st_frete_dest *= produto.val_icms_entrada / 100;
  produto.val_tot_bc_st_e = 0;

  produto.val_tot_st_cred = 0;
  produto.val_tot_bc_st_e = 0;

  produto.val_tot_vvarejo_guia = 0;

  const TIPO_OPERACAO = 0 + 1; // TODO - buscar do perfil

  if (
    (produto.flg_veda_cred || produto.flg_veda_cred_lim) &&
    produto.val_icms_entrada !== 0
  ) {
    if (TIPO_OPERACAO === 0) {
      const reducaoSaida = calculaReducaoBaseCalculo(
        produto.val_icms,
        produto.val_reducao,
      );

      const reducaoEntrada = calculaReducaoBaseCalculo(
        produto.val_icms_entrada,
        produto.val_reducao_entrada,
      );

      valPerRedBCEntrada = (1 - (reducaoSaida - reducaoEntrada)) * 100;
    } else {
      valPerRedBCEntrada = 0;
    }
  } else {
    valPerRedBCEntrada = produto.val_reducao_entrada;
  }

  valPerRedBCEntrada = parseFloat(valPerRedBCEntrada.toFixed(2));
  const valPerRedBCSaida = produto.val_reducao;

  let valVendaVarejoNovoCalc = 0;

  const FLGRATEIABCST = false; // TODO - verificar
  const isNovoCalculoST = false;

  const valTotST = produto.val_st_calc;

  if (
    produto.tipo_tributacao === 3 ||
    (FLGSTXML && produto.tipo_trib_entrada === 3)
  ) {
    if (!produto.flg_guia) {
      if (valTotVendaVarejo !== 0) {
        if (IMPORTNFE && !produto.calc_auto_imposto_xml) {
          produto.val_tot_bc_st_e = produto.val_bc_st_xml;
        } else if (!produto.flg_nao_calc_st) {
          produto.val_tot_bc_st_e = calculaReducaoBaseCalculo(
            valCustoTotSemIPISTFrete,
            valPerRedBCEntrada,
          );
        } else {
          produto.val_tot_bc_st_e = valTotBCSTEntrada;
        }
      } else if (!produto.flg_nao_calc_st && produto.per_pauta_iva_aplic > 0) {
        produto.val_tot_bc_st_e = calculaReducaoBaseCalculo(
          valCustoTotSemIPISTFrete,
          valPerRedBCEntrada,
        );
      } else {
        produto.val_tot_bc_st_e = valTotBCSTEntrada;
      }

      if (valTotBcSt > 0 && (FLGSTXML || FLGRATEIABCST)) {
        produto.val_tot_bc_st = valTotBcSt;
      } else if (!produto.flg_nao_calc_bcicms) {
        if (isNovoCalculoST) {
          valVendaVarejoNovoCalc = calculaVendaVarejo(
            produto.per_pauta_iva,
            produto.per_pauta_iva_ncm,
            produto.val_pauta_iva > 0 ? produto.val_pauta_iva : 0,
            valCustoTotSemST,
            produto.qtd_total,
            isFornPauta,
          );

          const val_tot_bc_st = calculaValorST(
            valVendaVarejoNovoCalc,
            produto.val_icms_entrada,
            produto.val_reducao_entrada,
            produto.val_icms_entrada,
            produto.val_reducao_entrada,
            produto.val_icms,
            produto.val_reducao,
            produto.per_pauta_iva,
            produto.per_pauta_iva,
            valCustoTotSemIPISTFrete,
            produto.tipo_trib_entrada,
            produto.tipo_tributacao,
            produto.flg_veda_cred,
            isFornRegimeST,
            isFornNaoTransIcms,
            isFornSimples,
            isInterestadual,
            isNovoCalculoST,
            1,
            valTotalSTCred,
          );
          produto.val_tot_bc_st = val_tot_bc_st;
        } else {
          const val_tot_bc_st = calculaReducaoBaseCalculo(
            valTotVendaVarejo,
            valPerRedBCSaida,
          );
          produto.val_tot_bc_st = val_tot_bc_st;
        }
      } else {
        produto.val_tot_bc_st = valTotBcSt;
      }

      // produto.val_tot_st = valTotST;

      if (
        produto.flg_veda_cred &&
        produto.val_icms_entrada !== 0 &&
        TIPO_OPERACAO === 1 &&
        !produto.flg_nao_calc_st
      ) {
        if (IMPORTNFE && FLGSTXML) {
          valTotalSTCred = produto.val_icms_st_xml;
        } else {
          valTotalSTCred =
            produto.val_tot_bc_st * (produto.val_icms_entrada / 100);
        }
      }

      if (valTotST > 0) {
        produto.val_tot_st_cred = valTotalSTCred;
      }
    } else {
      if (isFornNaoTransIcms) {
        produto.val_tot_bc_st_guia_e = 0;
      } else {
        produto.val_tot_bc_st_guia_e = calculaReducaoBaseCalculo(
          valCustoTotSemIPISTFrete,
          valPerRedBCEntrada,
        );
      }

      if (isNovoCalculoST) {
        valVendaVarejoNovoCalc = calculaVendaVarejo(
          produto.per_pauta_iva,
          produto.per_pauta_iva_ncm,
          produto.val_pauta_iva > 0 ? produto.val_pauta_iva : 0,
          valCustoTotSemST,
          produto.qtd_total,
          isFornPauta,
        );

        produto.val_tot_bc_st_guia_s = calculaValorST(
          valVendaVarejoNovoCalc,
          produto.val_icms_entrada,
          produto.val_reducao_entrada,
          produto.val_icms_entrada_ncm,
          produto.val_reducao_entrada_ncm,
          produto.val_icms,
          produto.val_reducao,
          produto.per_pauta_iva_ncm,
          produto.per_pauta_iva,
          valCustoTotSemIPISTFrete,
          produto.tipo_trib_entrada,
          produto.tipo_tributacao,
          produto.flg_veda_cred,
          isFornRegimeST,
          isFornNaoTransIcms,
          isFornSimples,
          isInterestadual,
          isNovoCalculoST,
          1,
          valTotalSTCred,
        );
      } else {
        produto.val_tot_bc_st_guia_s = calculaReducaoBaseCalculo(
          valTotVendaVarejoGuia,
          valPerRedBCSaida,
        );
      }
      produto.val_tot_vvarejo_guia = valTotVendaVarejoGuia;
      produto.val_tot_st_guia = valTotSTGuia;
    }

    const BZERAVALGUIASTDIF = false;

    if (
      !FLGSTXML &&
      !BZERAVALGUIASTDIF &&
      produto.val_icms_st_xml > 0 &&
      produto.val_st_calc > produto.val_icms_st_xml
    ) {
      produto.val_tot_st_guia_dif =
        produto.val_st_calc - produto.val_icms_st_xml;
      produto.val_tot_st_guia = produto.val_st_calc - produto.val_icms_st_xml;
      produto.val_tot_bc_st_guia_dif =
        produto.val_tot_bc_st - produto.val_bc_st_xml;

      if (produto.val_tot_bc_st_guia_dif < 0) {
        produto.val_tot_bc_st_guia_dif = 0;
      } else {
        produto.val_tot_bc_st = produto.val_bc_st_xml;
        produto.val_tot_bc_st_e = produto.val_bc_st_xml;
        produto.val_st_calc = produto.val_icms_st_xml;
        produto.val_tot_st = produto.val_icms_st_xml;
      }
    }

    const TIPONF = 0 + 1;

    if (
      !FLGSTXML &&
      !BZERAVALGUIASTDIF &&
      valTotST > produto.val_icms_st_xml &&
      produto.flg_nao_calc_st &&
      TIPONF !== 11
    ) {
      produto.val_tot_st_guia_dif = valTotST - produto.val_icms_st_xml;
      produto.val_tot_st_guia = 0;
      produto.val_tot_bc_st_guia_s = 0;
      produto.val_tot_bc_st_guia_dif =
        produto.val_tot_bc_st - produto.val_bc_st_xml;

      if (produto.val_tot_bc_st_guia_dif < 0) {
        produto.val_tot_bc_st_guia_dif = 0;
      }
    }
  }
};
