import { Produto } from '../../protocols';
import { calculaValorTabelaFinal } from '../Calculos';

type ValoresFinais = {
  valTotTabFinalGer: number;
  valTotTabelaFiscal: number;
};

type FieldsParams = {
  valTotTabLiq: number;
  valTotTabDesc: number;
  valTotTabLiqGer: number;
  valTotST: number;
  valTotSTFCP: number;
  valTotTabFiscal: number;
  isGuia: boolean;
  isFornRegimeST: boolean;
};

export const calculaValoresFinais = (
  produto: Produto,
  fields: FieldsParams,
): ValoresFinais => {
  const {
    valTotTabLiq,
    valTotTabDesc,
    valTotTabLiqGer,
    valTotST,
    valTotSTFCP,
    valTotTabFiscal,
    isGuia,
    isFornRegimeST,
  } = fields;

  produto.val_tabela_liq = valTotTabLiq;

  const valTotTabFinalGer = calculaValorTabelaFinal(
    valTotTabDesc,
    valTotTabLiqGer,
    valTotST + valTotSTFCP + produto.val_tot_fidelidade,
    produto.val_tot_ipi,
    true,
  );

  const valTotTabFinal = calculaValorTabelaFinal(
    valTotTabDesc,
    valTotTabLiq,
    valTotST + valTotSTFCP,
    produto.val_tot_ipi,
    true,
  );

  const valTotTabelaFiscal = calculaValorTabelaFinal(
    valTotTabDesc,
    valTotTabFiscal,
    isGuia && !isFornRegimeST ? 0 : valTotST + valTotSTFCP,
    produto.val_tot_ipi,
    true,
  );
  produto.val_tabela_final = valTotTabFinal;
  produto.val_tabela_final_emb =
    parseFloat(produto.val_tabela_final.toFixed(2)) / produto.qtd_entrada;

  return {
    valTotTabFinalGer,
    valTotTabelaFiscal,
  };
};
