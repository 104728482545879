import { Fornecedor, Produto } from '../../protocols';
import { calculaPMZ, calculaCustoMedio } from '../Calculos';

type FieldsParams = {
  tipoCusto: number;
};

export const calculaCustos = (produto: Produto, fields: FieldsParams): void => {
  const { tipoCusto } = fields;

  let valCusto = 0;

  switch (tipoCusto) {
    case 1: {
      valCusto = produto.val_custo_cheio;
      break;
    }
    case 2: {
      valCusto = produto.val_custo_scred;
      break;
    }
    case 3: {
      valCusto = produto.val_custo_sicms;
      break;
    }
    default: {
      valCusto = produto.val_custo_rep;
    }
  }

  produto.val_custo_medio = calculaCustoMedio(
    valCusto,
    produto.val_custo_medio_ant,
    produto.qtd_est_atual,
    produto.qtd_total,
  );

  produto.val_custo_pmz = calculaPMZ(
    produto.val_custo_scred,
    produto.per_icms_saida,
    produto.per_desp_op,
    0,
    0,
    produto.flg_nao_pis_cofins,
    produto.per_fcp_icms,
  );
};
