interface AliqIcmsRedBCEntrada {
  valAliqIcmsEntrada: number;
  perRedBCEntrada: number;
}

/**
 * @solidus - CalcAliqIcmsRedBCEntrada
 */

export function calculaAliqIcmsRedBCEntrada(
  valAliqIcmsEntrada: number,
  perRedBCEntrada: number,
  valAliqIcmsEntradaNCM: number,
  perRedBCEntradaNCM: number,
  valAliqIcmsSaida: number,
  perRedBCSaida: number,
  perPautaIVANCM: number,
  valPautaIVA: number,
  tipoTribEntrada: number,
  isVedaCredito: boolean,
  isRegST: boolean,
  isFornIntrICMS: boolean,
  isFornSimples: boolean,
  isInterestadual: boolean,
): AliqIcmsRedBCEntrada {
  const valAliqIcmsEntradaAnt = valAliqIcmsEntrada;
  if (isVedaCredito || isFornSimples || isInterestadual) {
    if (
      valAliqIcmsEntrada * (1 - perRedBCEntrada / 100) >
      valAliqIcmsSaida * (1 - perRedBCSaida / 100)
    ) {
      valAliqIcmsEntrada = valAliqIcmsSaida;
      perRedBCEntrada = perRedBCSaida;
    }
  } else {
    valAliqIcmsEntrada = 0;
    if (perPautaIVANCM > 0 || valPautaIVA > 0) {
      valAliqIcmsEntrada = valAliqIcmsEntradaNCM;
      perRedBCEntrada = perRedBCEntradaNCM;
    }
  }

  // Tipo Substituicao na Entrada = Somente calcula Crédito se for Substituição

  if (valAliqIcmsEntrada === 0) {
    if (tipoTribEntrada === 3 || isRegST) {
      valAliqIcmsEntrada = valAliqIcmsEntradaAnt;
    }
  }

  if (isFornIntrICMS && tipoTribEntrada !== 3) {
    valAliqIcmsEntrada = 0;
    perRedBCEntrada = 0;
  }

  return {
    valAliqIcmsEntrada,
    perRedBCEntrada,
  };
}
