import { Produto } from '../../protocols';
import { calculaICMS, calculaReducaoBaseCalculo } from '../Calculos';

type CreditoIcmsParams = {
  isFornPauta: boolean;
  isFornNaoTransIcms: boolean;
  lojaUf: string;
  forncedorUf: string;
  valTotTabLiq: number;
  valTotTabFiscal: number;
  valTotTabLiqGer: number;
};

type CreditoIcms = {
  valPerRedBCEntrada: number;
  valCredIcmsCalc: number;
  valCredIcmsGerencCalc: number;
};

export const calculaCreditoIcms = (
  produto: Produto,
  fields: CreditoIcmsParams,
  IMPORTNFE = false,
  ICMSXML = false,
): CreditoIcms => {
  const {
    isFornPauta,
    isFornNaoTransIcms,
    lojaUf,
    forncedorUf,
    valTotTabLiq,
    valTotTabFiscal,
    valTotTabLiqGer,
  } = fields;

  let valTotTabLiqCalcIcms = 0;
  let valTotTabLiqCalcIcmsGer = 0;

  if (produto.val_pauta_ipv > valTotTabLiq / produto.qtd_total && isFornPauta) {
    valTotTabLiqCalcIcms = produto.val_pauta_ipv * produto.qtd_total;
    valTotTabLiqCalcIcmsGer =
      produto.val_pauta_ipv * produto.qtd_total + produto.val_tot_fidelidade;
  } else {
    valTotTabLiqCalcIcms = valTotTabFiscal;
    valTotTabLiqCalcIcmsGer = valTotTabLiqGer + produto.val_tot_fidelidade;
  }

  /**
   * CONSTANTES A SEREM PROGRAMADAS E VERIFICADAS
   */

  const TIPO_OPERACAO = 0 + 1; // buscar do perfil

  let valCalcRed = 0;
  let valPerRedBCEntrada = 0;

  let isVedaCalcRed = false;

  if (
    produto.flg_veda_cred &&
    produto.val_icms_entrada !== 0 &&
    !produto.flg_veda_cred_lim
  ) {
    if (produto.val_reducao > 0) {
      valCalcRed =
        produto.val_icms - produto.val_icms * (produto.val_reducao / 100);
    } else {
      valCalcRed = produto.val_icms;
    }

    if (TIPO_OPERACAO === 0 && valCalcRed < produto.val_icms_entrada) {
      const reducaoSaida = calculaReducaoBaseCalculo(
        produto.val_icms,
        produto.val_reducao,
      );
      const reducaoEntrada = calculaReducaoBaseCalculo(
        produto.val_icms_entrada,
        produto.val_reducao_entrada,
      );

      valPerRedBCEntrada = (1 - reducaoSaida / reducaoEntrada) * 100;

      if (valPerRedBCEntrada < 0) {
        valPerRedBCEntrada = produto.val_reducao_entrada;
      }

      if (
        produto.val_icms === produto.val_icms_entrada &&
        produto.val_reducao === produto.val_reducao_entrada
      ) {
        valPerRedBCEntrada = produto.val_reducao;
        isVedaCalcRed = true;
      }
    } else {
      valPerRedBCEntrada = 0;
    }
  } else {
    valPerRedBCEntrada = produto.val_reducao_entrada;
  }

  if (produto.val_icms_entrada !== 0 && produto.flg_veda_cred_lim) {
    valCalcRed = produto.per_veda_cred_lim;

    if (TIPO_OPERACAO === 0 && valCalcRed < produto.val_icms_entrada) {
      const reducaoSaida = calculaReducaoBaseCalculo(valCalcRed, 0);
      const reducaoEntrada = calculaReducaoBaseCalculo(
        produto.val_icms_entrada,
        produto.val_reducao_entrada,
      );
      valPerRedBCEntrada = (1 - reducaoSaida / reducaoEntrada) * 100;

      if (valPerRedBCEntrada < 0) {
        valPerRedBCEntrada = produto.val_reducao_entrada;
      }
    } else {
      valPerRedBCEntrada = 0;
    }
  }

  if (!produto.flg_nao_calc_bcicms) {
    produto.val_tot_bc_icms_ger = 0;
    // por causa do tipo da tributacao [0, 2]
    if ([0, 2].includes(produto.tipo_trib_entrada)) {
      if (!isFornNaoTransIcms) {
        if (
          IMPORTNFE &&
          ICMSXML &&
          (!produto.flg_veda_cred_lim || produto.per_veda_cred_lim > 0)
        ) {
          produto.val_tot_bc_icms = produto.val_bc_icms_xml;
          produto.val_tot_bc_icms_ger = produto.val_bc_icms_xml;
        } else {
          produto.val_tot_bc_icms = calculaReducaoBaseCalculo(
            valTotTabLiqCalcIcms,
            valPerRedBCEntrada,
          );
        }
      }
    }

    if (produto.tipo_trib_entrada === 2) {
      if (
        produto.val_pauta_ipv > valTotTabLiq / produto.qtd_total &&
        isFornPauta
      ) {
        produto.val_tot_isento = parseFloat(
          (valTotTabFiscal - produto.val_tot_bc_icms).toFixed(2),
        );
      } else {
        produto.val_tot_isento = parseFloat(
          (valTotTabLiqCalcIcms - produto.val_tot_bc_icms).toFixed(2),
        );
      }

      if (produto.flg_veda_cred_lim) {
        produto.val_tot_outros_icms = produto.val_tot_isento;
        produto.val_tot_isento = 0;
      }
    } else if (
      produto.val_pauta_ipv > valTotTabLiq / produto.qtd_total &&
      isFornPauta
    ) {
      produto.val_tot_outros_icms = parseFloat(
        (valTotTabFiscal - produto.val_tot_bc_icms).toFixed(2),
      );
    } else {
      produto.val_tot_outros_icms = parseFloat(
        (valTotTabLiqCalcIcms - produto.val_tot_bc_icms).toFixed(2),
      );
    }

    if ([1, 8].includes(produto.tipo_trib_entrada)) {
      produto.val_tot_isento = valTotTabLiqCalcIcms;
      produto.val_tot_outros_icms = 0;
    }

    if (produto.val_tot_outros_icms < 0) {
      produto.val_tot_outros_icms *= -1;
    }

    if (produto.val_tot_isento < 0) {
      produto.val_tot_isento *= -1;
    }

    if (produto.val_tot_outros_icms !== 0 && produto.val_tot_isento !== 0) {
      produto.val_tot_outros_icms = 0;
      produto.val_tot_isento = 0;
    }

    produto.val_desc_suframa = 0;
    produto.per_icms_benef_cred = 0;
    if (valTotTabLiqCalcIcms !== 0 && !isFornNaoTransIcms) {
      // por causa do tipo da tributacao [0, 2]
      if ([0, 2].includes(produto.tipo_trib_entrada)) {
        if (IMPORTNFE && ICMSXML && !produto.flg_veda_cred_lim) {
          produto.val_tot_icms_op = produto.val_icms_xml;
          produto.val_tot_icms_op_gerenc = produto.val_tot_icms_op;
        } else {
          if (produto.val_desc_suframa > 0) {
            produto.val_tot_icms_op = calculaICMS(
              valTotTabLiqCalcIcms + produto.val_desc_suframa,
              produto.per_icms_entrada - produto.per_icms_benef_cred,
              0,
              produto.tipo_trib_entrada,
              false,
              false,
            );

            produto.val_tot_icms_op_gerenc = calculaICMS(
              valTotTabLiqCalcIcmsGer + produto.val_desc_suframa,
              produto.per_icms_entrada - produto.per_icms_benef_cred,
              0,
              produto.tipo_trib_entrada,
              false,
              false,
            );
          } else {
            produto.val_tot_icms_op = calculaICMS(
              valTotTabLiqCalcIcms,
              produto.per_icms_entrada - produto.per_icms_benef_cred,
              0,
              produto.tipo_trib_entrada,
              false,
              false,
            );

            produto.val_tot_icms_op_gerenc = calculaICMS(
              valTotTabLiqCalcIcmsGer,
              produto.per_icms_entrada - produto.per_icms_benef_cred,
              0,
              produto.tipo_trib_entrada,
              false,
              false,
            );
          }
          if (produto.per_icms_benef_cred > 0) {
            produto.val_tot_icms_op = parseFloat(
              (
                produto.val_tot_bc_icms *
                ((produto.val_icms_entrada - produto.per_icms_benef_cred) / 100)
              ).toFixed(2),
            );

            produto.val_tot_icms_op_gerenc = parseFloat(
              (
                produto.val_tot_bc_icms_ger *
                ((produto.val_icms_entrada - produto.per_icms_benef_cred) / 100)
              ).toFixed(2),
            );
          }
        }

        if (produto.flg_veda_cred || produto.flg_veda_cred_lim) {
          if (!isVedaCalcRed) {
            if (
              IMPORTNFE &&
              ICMSXML &&
              produto.per_veda_cred_lim > 0 &&
              valCalcRed === parseFloat(produto.per_icms_entrada.toFixed(2))
            ) {
              produto.val_tot_icms_op = calculaICMS(
                valTotTabLiqCalcIcms,
                produto.per_icms_entrada,
                0,
                produto.tipo_trib_entrada,
                false,
                false,
              );

              produto.val_tot_icms_op_gerenc = calculaICMS(
                valTotTabLiqCalcIcmsGer,
                produto.per_icms_entrada,
                0,
                produto.tipo_trib_entrada,
                false,
                false,
              );
            } else {
              produto.val_tot_icms_op = parseFloat(
                (
                  produto.val_tot_bc_icms *
                  (produto.per_icms_entrada / 100)
                ).toFixed(2),
              );

              produto.val_tot_icms_op_gerenc = parseFloat(
                (
                  produto.val_tot_bc_icms_ger *
                  (produto.per_icms_entrada / 100)
                ).toFixed(2),
              );
            }
          } else {
            produto.val_tot_icms_op = calculaICMS(
              valTotTabLiqCalcIcms,
              produto.per_icms_entrada,
              0,
              produto.tipo_trib_entrada,
              false,
              false,
            );

            produto.val_tot_icms_op_gerenc = calculaICMS(
              valTotTabLiqCalcIcmsGer,
              produto.per_icms_entrada,
              0,
              produto.tipo_trib_entrada,
              false,
              false,
            );
          }
        }
      } else if (produto.tipo_trib_entrada === 3) {
        produto.val_tot_icms_op = 0;
        produto.val_tot_icms_op_gerenc = 0;
      } else {
        produto.val_tot_icms_op = 0;
        produto.val_tot_icms_op_gerenc = 0;
      }
    }
  }

  if (
    !produto.flg_veda_cred ||
    (!produto.flg_veda_cred && !produto.flg_veda_cred_lim)
  ) {
    // verificar cliAjusteQuebra
    if (produto.val_tot_icms_op === 0) {
      if ([0, 2].includes(produto.tipo_trib_entrada)) {
        produto.val_tot_icms_op = calculaICMS(
          valTotTabLiq,
          produto.val_icms_entrada,
          produto.val_reducao_entrada,
          produto.tipo_trib_entrada,
          false,
          false,
        );
      } else if (produto.tipo_trib_entrada === 5) {
        if (lojaUf === 'SP') {
          if (produto.val_reducao > 0) {
            produto.val_tot_icms_op = calculaICMS(
              valTotTabLiq,
              produto.val_icms_entrada,
              produto.val_reducao_entrada,
              2,
              false,
              false,
            );
          } else {
            produto.val_tot_icms_op = calculaICMS(
              valTotTabLiq,
              produto.val_icms_entrada,
              produto.val_reducao_entrada,
              0,
              false,
              false,
            );
          }
        }
      }
      produto.val_tot_icms_op = parseFloat(produto.val_tot_icms_op.toFixed(3));
    }
  }

  let valCredIcmsCalc = 0;
  let valCredIcmsGerencCalc = 0;
  const valIcmsCalc = produto.val_icms_entrada;
  const valRedBCCalc = produto.val_reducao;

  if (!isFornNaoTransIcms && valIcmsCalc > 0) {
    valCredIcmsCalc =
      (calculaICMS(
        valTotTabLiqCalcIcms,
        valIcmsCalc,
        valRedBCCalc,
        produto.tipo_trib_entrada,
        false,
        false,
      ) +
        produto.val_tot_icms_fcp) /
      produto.qtd_total;

    valCredIcmsGerencCalc =
      (calculaICMS(
        valTotTabLiqCalcIcmsGer,
        valIcmsCalc,
        valRedBCCalc,
        produto.tipo_trib_entrada,
        false,
        false,
      ) +
        produto.val_tot_icms_fcp) /
      produto.qtd_total;
  } else {
    valCredIcmsCalc =
      (produto.val_tot_icms_op + produto.val_tot_icms_fcp) / produto.qtd_total;

    valCredIcmsGerencCalc =
      (produto.val_tot_icms_op_gerenc + produto.val_tot_icms_fcp) /
      produto.qtd_total;
  }

  produto.per_icms_ent_calc = produto.per_icms_entrada;

  // let valCredIcms = 0;
  // let valCredIcmsGer = 0;

  // TODO verificar se é GUIA

  if (produto.tipo_tributacao === 3 && lojaUf !== forncedorUf) {
    valCredIcmsCalc = 0;
    produto.per_icms_ent_calc = 0;
    valCredIcmsGerencCalc = 0;
  }

  if (
    produto.tipo_trib_entrada === 3 &&
    [0, 2].includes(produto.tipo_tributacao)
  ) {
    valCredIcmsCalc = 0;
    valCredIcmsGerencCalc = 0;
  }

  if (isFornNaoTransIcms && !produto.flg_nao_calc_bcicms) {
    valCredIcmsCalc = 0;
    produto.val_tot_icms_op = 0;
    produto.per_icms_ent_calc = 0;
    valCredIcmsGerencCalc = 0;
  }

  return {
    valPerRedBCEntrada,
    valCredIcmsCalc,
    valCredIcmsGerencCalc,
  };
};
