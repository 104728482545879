import { Produto } from '../../protocols';

export const defineVariaveis = (
  produto: Produto,
  xmlImported = false,
): void => {
  produto.val_tot_isento = 0;
  produto.val_tot_outros_icms = 0;
  produto.val_tot_bc_icms = 0;
  produto.val_tot_bc_st = 0;
  produto.val_tot_bc_st_guia_s = 0;
  produto.val_tot_bc_st_guia_e = 0;
  produto.val_tot_bc_st_guia_dif = 0;
  produto.val_tot_st_guia = 0;
  produto.val_tot_st_guia_dif = 0;
  produto.val_tot_bc_st_frete_dest = 0;
  produto.val_tabela_final = 0;
  produto.val_tabela_final_emb = 0;
  produto.val_custo_cheio = 0;
  produto.val_custo_rep = 0;
  produto.val_tot_icms_fcp = 0;
  produto.val_tot_icms_op = 0;
  produto.val_tot_icms_op_gerenc = 0;
  produto.val_tot_st_fcp = 0;
  produto.val_tot_indeniza = 0;
  produto.val_tot_verba = 0;
  produto.val_tot_fidelidade = 0;

  produto.val_peso = produto.val_peso === 0 ? 1 : produto.val_peso;

  produto.val_desp_acess = 0;
  produto.val_acrescimo = 0;
  produto.val_frete = 0;
  produto.val_frete_dest = 0;
  produto.val_desconto = 0;
  produto.val_ipi = 0;
  produto.val_st = 0;
  produto.val_indenizacao = 0;

  produto.val_imp_pis = 0;
  produto.val_imp_cofins = 0;

  const valTabela = produto.val_tabela || 0; // Valor Unitário
  /**
   * Fazer vProd + vOutro - vDesc para XML
   * val_produto_xml    val_desconto_xml
   * caso contrário manter cálculo do qtd_entrada * valTabela
   */
  if (xmlImported) {
    produto.subtotal = parseFloat(produto.val_produto_xml.toFixed(2));
  } else {
    produto.subtotal = produto.qtd_entrada * valTabela;
    produto.subtotal = parseFloat(produto.subtotal.toFixed(2));
  }

  const qtdTotal =
    (produto.qtd_entrada * produto.qtd_embalagem) / produto.val_peso;
  produto.qtd_total = qtdTotal;

  if (!produto.flg_ipv) {
    if (produto.val_peso - Math.trunc(produto.val_peso) === 0) {
      produto.qtd_total = Math.round(qtdTotal);
    }
  }
};
