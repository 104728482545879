import {
  Fornecedor,
  Loja,
  Produto,
  Tributacao,
} from '~/pages/EntradaNFE/protocols';
import { calculaICMS } from '../Calculos/Icms';
import { toast } from 'react-toastify';

export function buscaInfoTributacaoEntrada(
  produto: Produto,
  loja: Loja,
  fornecedor: Fornecedor,
  tributacoes: Tributacao[],
  cod_tributacao: number,
  cod_trib_simples: number,
  cfop: number,
  flg_optante_simples: boolean,
): void {
  if (
    flg_optante_simples &&
    [0, 2].includes(produto.tipo_tributacao) &&
    cod_trib_simples > 0
  ) {
    cod_tributacao = cod_trib_simples;
  }

  const tributacao = tributacoes.find(
    (item) => item.cod_tributacao === cod_tributacao,
  );

  if (!tributacao) {
    toast.warning('Tributação não selecionada');
    return;
  }
  let guiaST = false; // Operações Interestaduais e o produto é ST

  if (
    loja.uf !== fornecedor.des_uf &&
    produto.tipo_tributacao === 3 &&
    !fornecedor.flg_regime_st
  ) {
    guiaST = true;
  }

  if ([0, 2].includes(tributacao.tipo_tributacao) && guiaST) {
    // Busca a tributação do tipo ST
    const tributacaoST = tributacoes.find((reg) => {
      return (
        reg.val_icms === produto.val_icms_entrada &&
        reg.val_reducao === produto.val_reducao_entrada &&
        reg.tipo_tributacao === 3
      );
    });

    if (tributacaoST) {
      produto.cod_trib_entrada = tributacaoST.cod_tributacao;
      produto.val_icms_entrada = tributacaoST.val_icms;
      produto.tipo_trib_entrada = tributacaoST.tipo_tributacao;
      produto.val_reducao_entrada = tributacaoST.val_reducao;

      produto.per_icms_entrada = calculaICMS(
        0,
        tributacaoST.val_icms,
        tributacaoST.val_reducao,
        tributacaoST.tipo_tributacao,
        true,
        false,
      );

      produto.cod_sit_trib_entrada = tributacaoST.cod_situacao_tributaria;
      produto.des_trib_entrada = tributacaoST.des_tributacao;
      produto.tipo_encerramento_diferido =
        tributacaoST.tipo_encerramento_diferido;

      // const cfop = defineCFOP(0, false, )
    } else {
      throw new Error(
        'Não existe nenhum código de tributação que tenha mesmo Perc. de ICMS e Red.BC para Tipo Tributação: Substituição',
      );
    }
  } else {
    produto.cod_trib_entrada = tributacao.cod_tributacao;
    produto.val_icms_entrada = tributacao.val_icms;
    produto.tipo_trib_entrada = tributacao.tipo_tributacao;
    produto.val_reducao_entrada = tributacao.val_reducao;
    produto.per_icms_entrada = calculaICMS(
      0,
      tributacao.val_icms,
      tributacao.val_reducao,
      tributacao.tipo_tributacao,
      true,
      false,
    );
    produto.cod_sit_trib_entrada = tributacao.cod_situacao_tributaria;
    produto.des_trib_entrada = tributacao.des_tributacao;
    produto.tipo_encerramento_diferido = tributacao.tipo_encerramento_diferido;
  }

  if (
    fornecedor.flg_nao_transf_icms &&
    [0, 2].includes(produto.tipo_trib_entrada)
  ) {
    const tributacaoOutros = tributacoes.find((trib) => {
      return (
        trib.val_icms === 0 &&
        trib.cod_situacao_tributaria === '090' &&
        trib.tipo_tributacao === 6
      );
    });
    if (tributacaoOutros) {
      produto.cod_trib_entrada = tributacaoOutros.cod_tributacao;
      produto.val_icms_entrada = tributacaoOutros.val_icms;
      produto.tipo_trib_entrada = tributacaoOutros.tipo_tributacao;
      produto.val_reducao_entrada = tributacaoOutros.val_reducao;
      produto.per_icms_entrada = calculaICMS(
        0,
        tributacaoOutros.val_icms,
        tributacaoOutros.val_reducao,
        tributacaoOutros.tipo_tributacao,
        true,
        false,
      );
      produto.cod_sit_trib_entrada = tributacaoOutros.cod_situacao_tributaria;
      produto.des_trib_entrada = tributacaoOutros.des_tributacao;
      produto.tipo_encerramento_diferido =
        tributacaoOutros.tipo_encerramento_diferido;
    } else {
      throw new Error(
        `Não existe nenhuma tributação do tipo 'Outros ICMS' que tenha 0% de Valor de ICMS e CST 090.`,
      );
    }
  }

  if (
    flg_optante_simples &&
    [0, 2].includes(produto.tipo_tributacao) &&
    cod_trib_simples > 0
  ) {
    produto.cod_trib_entrada = cod_trib_simples;
    produto.per_icms_entrada = tributacao.val_icms;
    produto.val_reducao_entrada = tributacao.val_reducao;
    produto.tipo_trib_entrada = tributacao.tipo_tributacao;
    produto.cod_sit_trib_entrada = tributacao.cod_situacao_tributaria;
  } else if (
    String(cfop).slice(1, 4) === '556' &&
    [0, 2].includes(produto.tipo_tributacao)
  ) {
    const tributacaoOutros = tributacoes.find((trib) => {
      return (
        trib.val_icms === 0 &&
        trib.cod_situacao_tributaria === '090' &&
        trib.tipo_tributacao === 6
      );
    });
    if (tributacaoOutros) {
      produto.cod_trib_entrada = tributacaoOutros.cod_tributacao;
      produto.per_icms_entrada = tributacaoOutros.val_icms;
      produto.val_reducao_entrada = tributacaoOutros.val_reducao;
      produto.tipo_trib_entrada = tributacaoOutros.tipo_tributacao;
      produto.cod_sit_trib_entrada = tributacaoOutros.cod_situacao_tributaria;
    } else {
      throw new Error(
        `Produto '${produto.des_produto} - ${produto.cod_produto}' de Uso e Consumo mas não foi encontrada tributação do tipo 'Outros ICMS' e CST 090`,
      );
    }
  }

  // return produto;
}
