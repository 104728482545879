import { Fornecedor, Produto } from '../../protocols';
import {
  calculaCustoTotalSemST,
  calculaCustoTotSemIPIST,
  calculaValorST,
  calculaVendaVarejo,
} from '../Calculos';

type ValoresStVendaVarejo = {
  valCustoTotSemST: number;
  valCustoTotSemIPISTFrete: number;
  valTotBCSTEntrada: number;
  valTotBcSt: number;
  valTotVendaVarejoGuia: number;
  valTotSTGuia: number;
};

export const calculaStVendaVarejo = async (
  produto: Produto,
  fornecedor: Fornecedor,
  FLGSTXML = false,
): Promise<ValoresStVendaVarejo> => {
  const isNaoDescBCST = fornecedor.flg_nao_desc_bc_st;
  const isFornRegimeST = fornecedor.flg_regime_st;
  // const isInterestadual = loja.uf !== fornecedor.des_uf;
  const isInterestadual = false;
  const isFornPauta = fornecedor.flg_pauta;
  const isFornNaoTransIcms = fornecedor.flg_nao_transf_icms;
  const isFornSimples = fornecedor.flg_optante_simples;
  const isGuia = false; // TODO - PROGRAMAR

  let valIPICalcVendaVarejo = produto.val_tot_ipi;

  if (produto.val_pauta_iva !== 0) {
    valIPICalcVendaVarejo = 0;
  }

  const valCustoTotSemST = calculaCustoTotalSemST(
    produto.subtotal,
    1,
    produto.val_tot_desconto + produto.val_tot_indeniza, // ideniza
    produto.val_tot_acrescimo,
    produto.val_tot_desp_acess,
    valIPICalcVendaVarejo,
    produto.val_tot_frete,
    0, // VAL_TOT_FRETE_DEST
    0,
    isNaoDescBCST,
    isGuia,
    isFornRegimeST,
  );

  const valCustoTotSemIPISTFrete = calculaCustoTotSemIPIST(
    produto.subtotal,
    1,
    produto.val_tot_desconto + produto.val_tot_indeniza,
    produto.val_tot_acrescimo,
    produto.val_tot_desp_acess,
    produto.val_tot_frete,
    false,
  );

  const isNovoCalculoST = false;
  let valTotSTGuia = 0;
  let valTotVendaVarejoGuia = 0;
  const valTotalSTCred = 0;
  let valTotBcSt = 0;
  let valTotBCSTEntrada = 0;
  const flgGeraGuiaDiferido = false;

  const isGeraGuiaDiferido =
    produto.tipo_trib_entrada === 5 &&
    produto.tipo_encerramento_diferido === 0 &&
    produto.tipo_tributacao === 3 &&
    !isInterestadual;

  if (FLGSTXML && !isGeraGuiaDiferido) {
    produto.val_venda_varejo_calc = 0;
    if (isGuia && produto.val_tot_st_guia > 0) {
      produto.val_st_calc = produto.val_tot_st_guia;
    } else {
      produto.val_st_calc = produto.val_icms_st_xml;
    }
    valTotBcSt = produto.val_bc_st_xml;
    valTotBCSTEntrada = produto.val_tot_bc_st_e;

    if (produto.flg_nao_calc_bcicms) {
      valTotBcSt = produto.val_tot_bc_st;
    }
    produto.val_tot_st_fcp = produto.val_fcp_st_xml;
  } else {
    produto.val_venda_varejo_calc = calculaVendaVarejo(
      produto.per_pauta_iva,
      produto.per_pauta_iva_ncm,
      produto.val_pauta_iva > 0 ? produto.val_pauta_iva : 0,
      valCustoTotSemST,
      produto.qtd_total,
      isFornPauta,
    );

    let val_st = 0;
    if (FLGSTXML) {
      val_st = calculaValorST(
        produto.val_venda_varejo_calc,
        produto.val_icms_entrada,
        produto.val_reducao_entrada,
        isGeraGuiaDiferido ? 0 : produto.val_icms_entrada,
        isGeraGuiaDiferido ? 0 : produto.val_reducao_entrada,
        produto.val_icms_entrada,
        produto.val_reducao,
        produto.per_pauta_iva_ncm,
        produto.per_pauta_iva,
        valCustoTotSemIPISTFrete,
        produto.tipo_trib_entrada,
        produto.tipo_tributacao,
        produto.flg_veda_cred,
        isFornRegimeST,
        isFornNaoTransIcms,
        isFornSimples,
        isInterestadual,
        isNovoCalculoST,
        0,
        valTotalSTCred,
      );
    }
    produto.val_st_calc = val_st;
  }

  valTotVendaVarejoGuia = calculaVendaVarejo(
    produto.per_pauta_iva,
    produto.per_pauta_iva_ncm,
    produto.val_pauta_iva > 0 ? produto.val_pauta_iva : 0,
    valCustoTotSemST,
    produto.qtd_total,
    isFornPauta,
  );

  valTotSTGuia = calculaValorST(
    valTotVendaVarejoGuia,
    produto.val_icms_entrada,
    produto.val_reducao_entrada,
    flgGeraGuiaDiferido ? 0 : produto.val_icms_entrada_ncm,
    flgGeraGuiaDiferido ? 0 : produto.val_reducao_entrada_ncm,
    produto.val_icms,
    produto.val_reducao,
    produto.per_pauta_iva_ncm,
    produto.val_pauta_iva,
    valCustoTotSemIPISTFrete,
    produto.tipo_trib_entrada,
    produto.tipo_tributacao,
    produto.flg_veda_cred,
    isFornRegimeST,
    isFornNaoTransIcms,
    isFornSimples,
    isInterestadual,
    isNovoCalculoST,
    0,
    valTotalSTCred,
  );

  return {
    valCustoTotSemST,
    valCustoTotSemIPISTFrete,
    valTotBCSTEntrada,
    valTotBcSt,
    valTotVendaVarejoGuia,
    valTotSTGuia,
  };
};
