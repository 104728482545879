import { Fornecedor, Loja, Produto } from '../protocols';
import { somaCampos } from './Procedures';

export class Util {
  static recalcularProdutos(
    fornecedor: Fornecedor,
    loja: Loja,
    produtos: Produto[],
  ): Produto[] {
    const produtosRecalculados: any = [];

    produtos.map((produto) => {
      const serializedProduto = produto;
      if (!produto.flg_nao_calc_bc) {
        somaCampos(fornecedor, loja, serializedProduto);
      }
      produtosRecalculados.push(serializedProduto);
      return produto;
    });

    return produtosRecalculados;
  }

  static reordenarProdutos(produtos: Produto[]): Produto[] {
    produtos.map((produto, index) => {
      produto.num_item = index + 1;
      return produto;
    });
    return produtos;
  }
}
