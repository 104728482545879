import { Produto } from '../../protocols';
import { calculaValorFCPST } from '../Calculos';

type TotalStFcpParams = {
  isInterestadual: boolean;
  isFornRegimeST: boolean;
  isFornNaoTransIcms: boolean;
  isFornSimples: boolean;
  valCustoTotSemIPISTFrete: number;
};

export const calculaTotalStFcp = (
  produto: Produto,
  fields: TotalStFcpParams,
  TIPOREGIMEFCP: number,
  FLGSTXML = false,
): void => {
  const {
    isInterestadual,
    isFornRegimeST,
    isFornNaoTransIcms,
    isFornSimples,
    valCustoTotSemIPISTFrete,
  } = fields;

  const flgGeraGuiaDiferido = false;

  const isGeraGuiaDiferido =
    produto.tipo_trib_entrada === 5 &&
    produto.tipo_encerramento_diferido === 0 &&
    produto.tipo_tributacao === 3 &&
    !isInterestadual;

  // eslint-disable-next-line no-empty
  if (FLGSTXML && !isGeraGuiaDiferido) {
  } else if (produto.val_tot_bc_st > 0 && produto.per_fcp_st > 0) {
    if (TIPOREGIMEFCP === 1) {
      const val_tot_st_fcp = calculaValorFCPST(
        produto.val_venda_varejo_calc,
        produto.val_icms_entrada,
        produto.val_reducao_entrada,
        flgGeraGuiaDiferido ? 0 : produto.val_icms_entrada_ncm,
        flgGeraGuiaDiferido ? 0 : produto.val_reducao_entrada_ncm,
        produto.val_icms,
        produto.val_reducao,
        produto.per_pauta_iva_ncm,
        produto.val_pauta_iva,
        valCustoTotSemIPISTFrete,
        produto.tipo_trib_entrada,
        produto.tipo_tributacao,
        produto.flg_veda_cred,
        isFornRegimeST,
        isFornNaoTransIcms,
        isFornSimples,
        isInterestadual,
        produto.per_fcp_st,
        produto.per_fcp_icms,
      );
      produto.val_tot_st_fcp = val_tot_st_fcp;
    } else {
      const val_tot_st_fcp = parseFloat(
        (produto.val_tot_bc_st * (produto.per_fcp_st / 100)).toFixed(2),
      );
      produto.val_tot_st_fcp = val_tot_st_fcp;
    }
  } else {
    produto.val_tot_st_fcp = 0;
  }
};
